import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const chipVariants = cva(
  "inline-flex items-center rounded border-[1px] text-[14px] font-normal leading-5 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-gray-300 bg-white hover:bg-white/80",
        blueFilled:
          "border-primary-300 bg-primary-50 text-primary-600 hover:bg-primary-600/20",
      },
      size: {
        base: "py-[6px] px-[12px]",
        sm: "py-[2px] px-[8px]",
      },
      shape: {
        default: "rounded",
        circle: "rounded-[32px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "base",
      shape: "default",
    },
  }
);

export interface ChipProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof chipVariants> {}

const Chip = ({ className, variant, size, shape, ...props }: ChipProps) => {
  return (
    <div
      className={cn(chipVariants({ variant, size, shape }), className)}
      {...props}
    />
  );
};

export { Chip };
