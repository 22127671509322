import { cn } from "@/lib/utils";
import {
  RhDivider,
  RhIcon,
  RhLoader,
  RhPopover,
  RhPopoverMenu,
  RhPopoverToggle,
} from "@rhythm-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { CandidateStatusType, CandidateType } from "@/types/candidate";

import api from "@/services/api";

import { setOpenConfirmCandidateBuyInTakenDialog } from "@/redux/slice/confirmBuyinStatusChangeSlice";

import {
  candidateStageOptions,
  candidateStatusInterviewStageOptions,
  candidateStatusPostInterviewStageOptions,
  candidateStatusScreeningStageOptions,
} from "@/constants/candidates";
import mixPanelEventName from "@/constants/mixPanelEventName";

import useTrackEvent from "@/hooks/useTrackEvent";

import {
  setIsCandidateDropDialogOpen,
  setIsCandidateReconsiderStatusDialogOpen,
  setIsCandidateRejectDialogOpen,
} from "./candidateStatusTogglerSlice";

const CandidateStatusToggler = ({
  candidate,
  isViewTableCell = false,
}: {
  candidate: CandidateType | undefined;
  isViewTableCell?: boolean;
}) => {
  const candidateId = candidate?.id;
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const trackEventFn = useTrackEvent();

  const queryClient = useQueryClient();
  const postCandidateStatus = useMutation({
    mutationFn: (candidateStatus: CandidateStatusType) =>
      typeof candidateId !== "number"
        ? Promise.reject(new Error(`candidate is null: ${candidateId}`))
        : api.candidate.patchCandidate({
            id: candidateId,
            candidate_status: candidateStatus,
          }),
    onSuccess(_, candidateStatus) {
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATES", "LIST"],
      });
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATES", "ITEM", { candidateId: candidate?.id }],
      });

      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATE_ACTIVITIES",
          {
            candidateId: candidate?.id,
          },
        ],
      });

      queryClient.invalidateQueries({
        queryKey: [
          "ACTIVITY_LOG_ACTORS",
          {
            candidateId: candidate?.id,
          },
        ],
      });

      trackEventFn({
        name: mixPanelEventName.CANDIDATE_STATUS_FOR_RECRUITER_CHANGE,
        properties: {
          candidateId,
          statusChangedFrom: candidate?.candidate_status,
          statusChangedTo: candidateStatus,
        },
      });
    },
  });

  const candidateStatusCurrentStageGroup = candidateStageOptions.find(
    (item) => item.value === candidate?.candidate_status
  )?.type;

  const isCandidateInScreeningGroup =
    candidateStatusCurrentStageGroup === "screening_stage";

  const currentCandidateStatusFormatted = candidate?.candidate_status
    ? candidateStageOptions.find(
        (item) => item.value === candidate.candidate_status
      )?.label
    : "Set candidate status";

  return (
    <div
      className={cn("max-h-fit flex items-center gap-tnano", {
        "w-full": isViewTableCell,
      })}
    >
      {!isViewTableCell && candidate?.candidate_stage && (
        <span className="rounded-[4px] py-[2px] px-[10px] bg-[#F3F9FF] border-l-[4px] border-l-primary-600">
          <p>
            {candidate?.candidate_stage === "screening" && "Screening Stage"}
            {candidate?.candidate_stage === "interview" && "Z Interview Stage"}
            {candidate?.candidate_stage === "post_interview" &&
              "Post Interview Stage"}
          </p>
        </span>
      )}

      <RhPopover
        isOpen={isOpen}
        placement="bottom-start"
        offset={[-12, 10]}
        className="bg-white"
        onToggle={() => {
          setIsOpen(false);
        }}
      >
        <RhPopoverToggle
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((state) => !state);
          }}
          asChild
        >
          <div
            className={cn({
              "border rounded-[32px] border-primary-300 text-primary-600 py-[2px] px-tnano h-min flex items-center gap-tpico":
                !isViewTableCell,
              "border rounded-[32px] border-danger-600 bg-danger-600 text-white  py-[2px] px-tnano h-min flex items-center gap-tpico":
                !isViewTableCell &&
                (candidate?.candidate_status === "drop_candidate" ||
                  candidate?.candidate_status === "reject_candidate"),

              "flex items-center justify-between cursor-pointer w-full":
                isViewTableCell,
            })}
          >
            <p data-testid="candidate-status" className="select-none">
              {currentCandidateStatusFormatted}
            </p>
            {!isViewTableCell ? (
              <>
                {postCandidateStatus.isLoading ? (
                  <RhLoader />
                ) : (
                  <RhIcon icon="tabler:chevron-down"></RhIcon>
                )}
              </>
            ) : (
              <>
                {postCandidateStatus.isLoading ? (
                  <RhLoader />
                ) : (
                  <RhIcon
                    data-testid="buyin-dropdown-table-view"
                    className={`${"text-gray-600"} h-[24px] w-[24px]`}
                    icon="tabler:chevron-down"
                  ></RhIcon>
                )}
              </>
            )}
          </div>
        </RhPopoverToggle>

        {candidate?.candidate_status === "drop_candidate" ||
        candidate?.candidate_status === "reject_candidate" ? (
          <>
            <RhPopoverMenu
              className={cn(`RhPopoverMenu w-[250px]`)}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() =>
                  candidate &&
                  dispatch(
                    setIsCandidateReconsiderStatusDialogOpen({
                      targetCandidate: candidate,
                    })
                  )
                }
                className="RhPopoverMenuItem"
              >
                <p data-testid="reconsider-candidate-option">
                  Reconsider Candidate
                </p>
              </button>
            </RhPopoverMenu>
          </>
        ) : (
          <RhPopoverMenu
            className={cn(`RhPopoverMenu w-[250px]`, {
              "[&_button]:pointer-events-none": postCandidateStatus.isLoading,
            })}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="py-tpico px-tsm">
              <p className="body-small text-hint">
                {(isCandidateInScreeningGroup ||
                  !candidateStatusCurrentStageGroup ||
                  !candidate?.candidate_status?.length) &&
                  "Screening Stage"}
                {candidateStatusCurrentStageGroup === "interview_stage" &&
                  "Z Interview Stage"}
                {candidateStatusCurrentStageGroup === "post_interview_stage" &&
                  "Post Interview Stage"}
              </p>
            </div>

            {(!candidate?.candidate_status?.length ||
              candidateStatusCurrentStageGroup === "screening_stage") &&
              candidateStatusScreeningStageOptions.map((item, idx) => (
                <button
                  data-testid="screening-status-options"
                  key={idx}
                  className={cn("RhPopoverMenuItem", {
                    RhPopoverMenuItemActive:
                      candidate?.candidate_status === item.value,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (candidate && item.value === "buyin_taken") {
                      dispatch(
                        setOpenConfirmCandidateBuyInTakenDialog({
                          targetCandidate: candidate,
                        })
                      );
                    } else {
                      postCandidateStatus.mutate(item.value);
                      setIsOpen(false);
                    }
                  }}
                >
                  <p>{item.label}</p>
                </button>
              ))}

            {candidateStatusCurrentStageGroup === "interview_stage" &&
              candidateStatusInterviewStageOptions.map((item) => (
                <button
                  key={item.value}
                  className={cn("RhPopoverMenuItem", {
                    RhPopoverMenuItemActive:
                      candidate?.candidate_status === item.value,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();

                    postCandidateStatus.mutate(item.value);
                    setIsOpen(false);
                  }}
                >
                  <p>{item.label}</p>
                </button>
              ))}

            {candidateStatusCurrentStageGroup === "post_interview_stage" &&
              candidateStatusPostInterviewStageOptions.map((item) => (
                <button
                  key={item.value}
                  className={cn("RhPopoverMenuItem", {
                    RhPopoverMenuItemActive:
                      candidate?.candidate_status === item.value,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();

                    postCandidateStatus.mutate(item.value);
                    setIsOpen(false);
                  }}
                >
                  <p>{item.label}</p>
                </button>
              ))}

            <RhDivider />
            <DestructiveActions
              candidate={candidate}
              hideRejectButton={
                candidateStatusCurrentStageGroup === "post_interview_stage"
              }
            />

            {(isCandidateInScreeningGroup ||
              !candidateStatusCurrentStageGroup ||
              !candidate?.candidate_status?.length) && (
              <>
                <RhDivider />
                <button
                  className="RhPopoverMenuItem"
                  onClick={() => {
                    postCandidateStatus.mutate("to_schedule");
                    setIsOpen(false);
                  }}
                >
                  <p>Move to “To Schedule”</p>
                </button>
              </>
            )}

            {candidateStatusCurrentStageGroup === "interview_stage" && (
              <>
                <RhDivider />
                <button
                  className="RhPopoverMenuItem"
                  onClick={() => {
                    postCandidateStatus.mutate("completed");
                    setIsOpen(false);
                  }}
                >
                  <p>Move to “Completed”</p>
                </button>
              </>
            )}
          </RhPopoverMenu>
        )}
      </RhPopover>
    </div>
  );
};

export default CandidateStatusToggler;

const DestructiveActions = ({
  candidate,
  hideRejectButton,
}: {
  candidate: CandidateType | undefined;
  hideRejectButton: boolean;
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {!hideRejectButton && (
        <button
          onClick={() =>
            candidate &&
            dispatch(
              setIsCandidateRejectDialogOpen({ targetCandidate: candidate })
            )
          }
          className="RhPopoverMenuItemDanger justify-between"
        >
          <p data-testid="reject-candidate-option">Reject Candidate</p>
          <RhIcon icon="tabler:chevron-right" />
        </button>
      )}

      <button
        onClick={() =>
          candidate &&
          dispatch(setIsCandidateDropDialogOpen({ targetCandidate: candidate }))
        }
        className="RhPopoverMenuItemDanger justify-between"
      >
        <p data-testid="drop-candidate-option">Candidate Drop</p>
        <RhIcon icon="tabler:chevron-right" />
      </button>
    </>
  );
};
