//
import candidateStatusTogglerSlice from "@/views/candidates/components/CandidateStatusToggler/candidateStatusTogglerSlice";
import tableViewSlice from "@/views/candidates/TableView/tableViewSlice";
import { configureStore } from "@reduxjs/toolkit";

import { api } from "../api";
import {
  listenerForLocalStorageMiddleware,
  rtkQueryErrorLogger,
} from "../middleware";
import candidateInterviewScheduleSlice from "../slice/candidateInterviewScheduleSlice";
import candidateSearchResultCountSlice from "../slice/candidateSearchResultCountSlice";
import candidateStatusPopoverSlice from "../slice/candidateStatusPopoverSlice";
import confirmBuyinStatusChangeSlice from "../slice/confirmBuyinStatusChangeSlice";
import confirmCandidateJobMatchSlice from "../slice/confirmCandidateJobMatchSlice";
import confirmCandidateMutateSlice from "../slice/confirmCandidateMutateSlice";
import customizeTableViewForCandidateListDialogSlice from "../slice/customizeTableViewForCandidateListDialogSlice";
import jobFilterSlice from "../slice/jobFilterSlice";
import matchCandidateToJobDialogSlice from "../slice/matchCandidateToJobDialogSlice";
import recruiterActionOnCandidateProfileSlice from "../slice/recruiterActionOnCandidateProfileSlice";
import bulkCandidateActionSlice from "../slice/recruiterView/CandidateList/bulkCandidateActionSlice";
import bulkSelectedCandidatesSlice from "../slice/recruiterView/CandidateList/bulkSelectedCandidatesSlice";
import currentlyAppliedCandidateSavedSearchSlice from "../slice/recruiterView/CandidateList/currentlyAppliedCandidateSavedSearchSlice";
import isExpandedNoteDailogOpenSlice from "../slice/recruiterView/CandidateList/isExpandedNoteDailogOpenSlice";
import isPrimaryReportSetterDialogOpenSlice from "../slice/recruiterView/CandidateList/isPrimaryReportSetterDialogOpenSlice";
import isReconsiderCandidateDialogOpenSlice from "../slice/recruiterView/CandidateList/isReconsiderCandidateDialogOpenSlice";
import isRejectCandidateDialogOpenSlice from "../slice/recruiterView/CandidateList/isRejectCandidateDialogOpenSlice";
import isSavedSearchListDrawerOpenSlice from "../slice/recruiterView/CandidateList/isSavedSearchListDrawerOpenSlice";
import selectPrimaryReportSlice from "../slice/selectPrimaryReportSlice";
import suggestActionToClientSlice from "../slice/suggestActionToClientSlice";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    jobFilter: jobFilterSlice,
    suggestActionToClientSlice,
    candidateStatusPopoverSlice,
    confirmCandidateMutateSlice,
    confirmBuyinStatusChangeSlice,
    candidateInterviewScheduleSlice,
    selectPrimaryReportSlice,
    matchCandidateToJobDialogSlice,
    confirmCandidateJobMatchSlice,
    recruiterActionOnCandidateProfileSlice,
    customizeTableViewForCandidateListDialogSlice,
    candidateSearchResultCountSlice,
    // recruiter view slice
    bulkSelectedCandidatesSlice,
    bulkCandidateActionSlice,
    isPrimaryReportSetterDialogOpenSlice,
    isReconsiderCandidateDialogOpenSlice,
    isRejectCandidateDialogOpenSlice,
    isExpandedNoteDailogOpenSlice,
    isSavedSearchListDrawerOpenSlice,
    currentlyAppliedCandidateSavedSearchSlice,
    tableViewSlice,
    candidateStatusTogglerSlice,
    //
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(rtkQueryErrorLogger)
      .concat(listenerForLocalStorageMiddleware.middleware),
});
export default store;
